<lib-menu
  [label]="lang.current.charAt(0).toUpperCase() + lang.current.slice(1)"
  [a11yAltText]="['langSwitcher.alt.text'] | translate"
  [collapseOnMobile]="collapseOnMobile"
  [posMode]="posMode"
  [outlined]="true"
  icon="planet"
>
  @for (lang of filteredLangs; track lang) {
    <lib-menu-item [menuItemLink]="getMenuItemLink(lang.value)">
      <span class="font-bold" [attr.lang]="lang.value">{{ lang.label }}</span>
    </lib-menu-item>
  }
</lib-menu>
