<div
  class="menu-global"
  [style.left]="menuPosition[0] + 'px'"
  [style.top]="menuPosition[1] + 'px'"
  [style.minWidth]="menuMinWidth + 'px'"
  [id]="menuId"
  tabindex="-1"
  [attr.role]="menuOpen ? 'menu' : ''"
  [class.on-top]="menuOnTop"
  [class.open]="menuOpen"
  #menContainer
>
  <div class="menu">
    <ng-template #template></ng-template>
  </div>
</div>

@if (menuOpen) {
  <div class="menu-global-bg" (click)="close()"></div>
}
