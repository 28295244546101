@if (a11yAltText()) {
  <p class="sr-only" [id]="a11yAltTextId">{{ a11yAltText() }}</p>
}
<button
  mat-button
  type="button"
  class="kitsune-menu"
  [ngClass]="{ 'kitsune-menu__outlined': outlined(), 'kitsune-menu__collapse': collapseOnMobile() }"
  [attr.aria-labelledby]="a11yAltText() ? a11yAltTextId : null"
  aria-haspopup="menu"
  (click)="open()"
  #menuButton
>
  <div class="flex items-center">
    @if (icon()) {
      <lib-svg
        [svg]="icon()"
        [class]="iconClass() + ' fill-white'"
        [ngClass]="{
          'sr-only sm:not-sr-only !hidden sm:!block': collapseOnMobile(),
          'fill-black': posMode(),
          'fill-white': !posMode(),
        }"
      ></lib-svg>
    }
    <span
      class="kitsune-menu__label text-white font-bold text-sm xl:text-base line-clamp-1"
      [ngClass]="{ 'text-black': posMode(), 'text-white': !posMode() }"
    >
      {{ label() }}
    </span>
    <lib-svg
      svg="fontawesome/chevron-down"
      [ngClass]="{
        'sr-only sm:not-sr-only !hidden sm:!block': collapseOnMobile(),
        'fill-black': posMode(),
        'fill-white': !posMode(),
      }"
    ></lib-svg>
  </div>
</button>
<ng-template #menuContent>
  @for (item of menuItems; track item) {
    @if (item.menuItemLink()?.routerLink || item.menuItemLink()?.rawUrl) {
      <div class="flex items-center hover:bg-[--color-gray-opacity]">
        @if (item.icon()) {
          <lib-svg
            [svg]="item.icon()"
            class="mr-3"
            [ngClass]="{
              'fill-black': posMode(),
              'fill-white': !posMode(),
            }"
          ></lib-svg>
        }
        @if (item.menuItemLink().routerLink) {
          <a
            [ngClass]="{
              'mx-4': !item.icon(),
            }"
            mat-menu-item
            [routerLink]="item.menuItemLink().routerLink"
            [routerLinkActive]="item.menuItemLink().routerLinkActive ?? ''"
            [queryParams]="item.menuItemLink().queryParams"
            [queryParamsHandling]="item.menuItemLink().queryParamsHandling"
            [fragment]="item.menuItemLink().fragment"
          >
            <ng-template [ngTemplateOutlet]="item.template"></ng-template>
          </a>
        }
        @if (item.menuItemLink().rawUrl) {
          <a
            [ngClass]="{
              'mx-2': !item.icon(),
            }"
            mat-menu-item
            [attr.href]="item.menuItemLink().rawUrl"
          >
            <ng-template [ngTemplateOutlet]="item.template"></ng-template>
          </a>
        }
      </div>
    } @else {
      <div class="flex items-center hover:bg-[--color-gray-opacity]">
        @if (item.icon()) {
          <lib-svg
            [svg]="item.icon()"
            [ngClass]="{
              'fill-black': posMode(),
              'fill-white': !posMode(),
            }"
          ></lib-svg>
        }
        <button
          mat-menu-item
          type="button"
          class="block w-full"
          (click)="close()"
          [ngClass]="{
            'mx-2': !item.icon(),
          }"
        >
          <ng-template [ngTemplateOutlet]="item.template"></ng-template>
        </button>
      </div>
    }
  }
</ng-template>
